
.nameEmail{
    margin: 1em 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* width:'auto' */
}



form{
display: flex;
flex-direction: column;
justify-content: center;
}

@media screen and (max-width:900px) {
.nameEmail{
    margin: 1em 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

}

