.contactInfo{
    display: flex;
    justify-content: center;

}
.contactInfo a{
    margin: 1em;

}

h1{
    display: flex;
justify-content: center;
}