@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
/* 
html {
background-color: black;
} */

#homePage {
    display: flex;
    flex-direction: row;
    width:90%;
    height: 100%;
    /* height:90%;
    margin:5%; */
    justify-content: space-between;
    /* font-family: "Quicksand", sans-serif; */
    font-weight: 400;
    /* border: 10px black solid; */
    align-items: center; /* Add this line */

}

.intro {
    text-align: center;
    width:48%;
    margin-right: 2%;
    height: 82vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.intro img{
    width:25%;
    height:auto;
    border-radius: 50%;
    align-self: center;
    margin-top: 1rem;
}

.projectCarousel {
    width:48%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-left: 2%;
    height: 82vh;
    margin-top: 1rem;


}


.opaque-block {
    background-color: rgba(222, 220, 211, 0.9);
    color: black;

}



@media screen and (max-width: 900px) {
    html {
        flex-direction: column;
        justify-content: center;
        /* background-color: black; */
    }
    .projectCarousel {
        /* try below */
        width: 96%;
        margin: 2% 2% 30% 2% ;
        /* border: 1px solid black; */
        height: fit-content;
    }

    .intro img{
        width:50vw;
        height:auto;
        border-radius: 50%;
    }
    .intro {
        width: 96%;
        margin:  2%;
        height: fit-content;
    }
}


@media screen and (min-width:1325px) {
    #homePage {
        display: flex;
        flex-direction: row;
        width:100%;
        height: 100%;
      
        justify-content: space-between;
        /* font-family: "Quicksand", sans-serif; */
        font-weight: 400;
        border: 10px black solid;
        align-items: center; /* Add this line */
    
    }
    
    .intro {
        width:42%;
        margin-right: 8%;
        height:81vh;
       margin-top: 0;
    }
    
    .intro img{
        width:35%;
        height:auto;
        border-radius: 50%;
        align-self: center;
        margin: 1rem 0;
    }
    
    .projectCarousel {
        width:42%;
        margin-left: 8%;
        height: 81vh;
        margin-top: 0;
    
    
    }

}